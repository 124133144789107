import { TYPE_BATCH } from '@/services/providers/sampleStrategy/StrategyManager'

export class SampleBatchStrategy {
  constructor(data) {
    this._batchId = data.batch_id
    this._batchInvoice = data.invoice
    this._quantity = data.sample_quantity
    this._umsId = data.u_m_s_id.key
    this._ums = {
      code: data.u_m_s_id.key,
      name: data.u_m_s_id.label,
    }
    this._reference = data.reference
  }

  buildSampleObject() {
    return {
      material_type: TYPE_BATCH,
      batch_id: this._batchId,
      batchInvoice: this._batchInvoice,
      quantity: this._quantity,
      u_m_s_id: this._umsId,
      ums: this._ums,
      reference: this._reference,
    }
  }
}
