import moment from 'moment'
import {
  StrategyManager, TYPE_BATCH,
  TYPE_MANUAL,
  TYPE_REMAINDER,
  TYPE_WAREHOUSE,
} from '@/services/providers/sampleStrategy/StrategyManager'
import { SampleBatchStrategy } from '@/services/providers/sampleStrategy/SampleBatchStrategy'
import { SampleManualStrategy } from '@/services/providers/sampleStrategy/SampleManualStrategy'
import { SampleRemainderStrategy } from '@/services/providers/sampleStrategy/SampleRemainderStrategy'
import { SampleWarehouseStrategy } from '@/services/providers/sampleStrategy/SampleWarehouseStrategy'
import { Strategy } from '@/services/providers/sampleStrategy/Strategy'
import api from '@/services/axios'
import { deserialize } from 'jsonapi-fractal'

const resourceData = [
  {
    id: 1,
    workCenters: {
      code: 'AP04',
      name: 'APAXCO',
    },
    code: '0000000001',
    manual_code: 'ECO-123',
    invoice: 'CE-00001',
    sample_type: 'Interno',
    containerTypes: {
      code: 'CON01',
      name: 'Totes',
    },
    quantity: 100,
    ums: {
      code: 'KG',
      name: 'Kilogramos',
    },
    materials: {
      material_type: 'Residuo',
      name: 'Lodo Contaminado',
      discharge_material_id: '1',
      currents: {
        code: 'LOD01',
        name: 'Lodos',
      },
    },
    customers: {
      customerType: 'Cliente',
      code: '4256854',
      name: 'SKF MÉXICO S.A. DE C.V.',
    },
    receipt_dated_at: moment(),
    profile_created_at: moment(),
    sample_status: 'Pendiente', // En proceso
    warehouse_status: 'Sin Asignar',
    sampleWarehouses: {
      potential_dangerous: 'Peligroso',
      sampleContainerTypes: {
        code: 'VID01',
        name: 'Vidrio',
      },
      last_dated_at: moment(),
      sampleStorageAreas: {
        code: 'A',
        name: 'Refrigerador',
      },
      timeStorageTimes: {
        code: 'L',
        name: '30 días',
      },
    },
  },
  {
    id: 2,
    workCenters: {
      code: 'AP04',
      name: 'APAXCO',
    },
    code: '0000000002',
    manual_code: 'ECO-124',
    invoice: 'CE-00002',
    sample_type: 'Externo',
    containerTypes: {
      code: 'CON01',
      name: 'Totes',
    },
    quantity: 100,
    ums: {
      code: 'KG',
      name: 'Kilogramos',
    },
    materials: {
      material_type: 'Residuo',
      name: 'Lodo Contaminado',
      currents: {
        code: 'LOD01',
        name: 'Lodos',
      },
    },
    customers: {
      customerType: 'Cliente',
      code: '4256854',
      name: 'SKF MÉXICO S.A. DE C.V.',
    },
    receipt_dated_at: moment(),
    profile_created_at: moment(),
    sample_status: 'Pendiente',
    warehouse_status: 'Sin Asignar',
    sampleWarehouses: {
      potential_dangerous: 'Peligroso',
      sampleContainerTypes: {
        code: 'VID01',
        name: 'Vidrio',
      },
      last_dated_at: moment(),
      sampleStorageAreas: {
        code: 'A',
        name: 'Refrigerador',
      },
      timeStorageTimes: {
        code: 'L',
        name: '30 días',
      },
    },
  },
  {
    id: 3,
    workCenters: {
      code: 'AP04',
      name: 'APAXCO',
    },
    code: '0000000003',
    manual_code: 'ECO-126',
    invoice: 'CE-00003',
    sample_type: 'Prueba de Compatibilidad',
    containerTypes: {
      code: 'CON01',
      name: 'Totes',
    },
    quantity: 100,
    ums: {
      code: 'KG',
      name: 'Kilogramos',
    },
    materials: {
      material_type: 'Residuo',
      name: 'Lodo Contaminado',
      currents: {
        code: 'LOD01',
        name: 'Lodos',
      },
    },
    customers: {
      customerType: 'Cliente',
      code: '4256854',
      name: 'SKF MÉXICO S.A. DE C.V.',
    },
    receipt_dated_at: moment(),
    profile_created_at: moment(),
    sample_status: 'Pendiente',
    warehouse_status: 'Sin Asignar',
    sampleWarehouses: {
      potential_dangerous: 'Peligroso',
      sampleContainerTypes: {
        code: 'VID01',
        name: 'Vidrio',
      },
      last_dated_at: moment(),
      sampleStorageAreas: {
        code: 'A',
        name: 'Refrigerador',
      },
      timeStorageTimes: {
        code: 'L',
        name: '30 días',
      },
    },
  },
  {
    id: 4,
    workCenters: {
      code: 'AP04',
      name: 'APAXCO',
    },
    code: '0000000004',
    manual_code: 'ECO-128',
    invoice: 'CE-00004',
    sample_type: 'Prueba de Lote Terminado',
    containerTypes: {
      code: 'CON01',
      name: 'Totes',
    },
    quantity: 100,
    ums: {
      code: 'KG',
      name: 'Kilogramos',
    },
    materials: {
      material_type: 'Residuo',
      name: 'Lodo Contaminado',
      currents: {
        code: 'LOD01',
        name: 'Lodos',
      },
    },
    customers: {
      customerType: 'Cliente',
      code: '4256854',
      name: 'SKF MÉXICO S.A. DE C.V.',
    },
    receipt_dated_at: moment(),
    profile_created_at: moment(),
    sample_status: 'Pendiente',
    warehouse_status: 'Sin Asignar',
    sampleWarehouses: {
      potential_dangerous: 'Peligroso',
      sampleContainerTypes: {
        code: 'VID01',
        name: 'Vidrio',
      },
      last_dated_at: moment(),
      sampleStorageAreas: {
        code: 'A',
        name: 'Refrigerador',
      },
      timeStorageTimes: {
        code: 'L',
        name: '30 días',
      },
    },
  },
]

const sampleStrategies = [
  { name: TYPE_REMAINDER, strategyName: 'sampleRemainderStrategy' },
  { name: TYPE_MANUAL, strategyName: 'sampleManualStrategy' },
  { name: TYPE_WAREHOUSE, strategyName: 'sampleWarehouseStrategy' },
  { name: TYPE_BATCH, strategyName: 'sampleBatchStrategy' },
]

export class SampleReceiptProvider {
  async get(filters = {}) {
    let data = []
    const basicParams = {
      sortBy: 'code',
      include: 'workCenters,ums',
    }
    const params = { ...basicParams, ...filters }
    await api.get('/receiptSamples', params).then(response => {
      data = deserialize(response.data)
    })
    return data
  }

  async getConstant() {
    return resourceData
  }

  fillStrategies(sampleBatch, sampleManual, sampleRemainder, sampleWarehouse) {
    const strategyManager = new StrategyManager()
    const sampleBatchStrategy = new Strategy(
      'sampleBatchStrategy',
      () => new SampleBatchStrategy(sampleBatch).buildSampleObject(),
    )
    const sampleManualStrategy = new Strategy(
      'sampleManualStrategy',
      () => new SampleManualStrategy(sampleManual).buildSampleObject(),
    )
    const sampleRemainderStrategy = new Strategy(
      'sampleRemainderStrategy',
      () => new SampleRemainderStrategy(sampleRemainder).buildSampleObject(),
    )
    const sampleWarehouseStrategy = new Strategy(
      'sampleWarehouseStrategy',
      () => new SampleWarehouseStrategy(sampleWarehouse).buildSampleObject(),
    )

    strategyManager.addStrategy(sampleBatchStrategy)
    strategyManager.addStrategy(sampleManualStrategy)
    strategyManager.addStrategy(sampleRemainderStrategy)
    strategyManager.addStrategy(sampleWarehouseStrategy)

    return strategyManager
  }

  selectStrategy(name) {
    return sampleStrategies.find(strategy => strategy.name === name)
  }
}
