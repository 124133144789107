import { render, staticRenderFns } from "./sampleViewModal.vue?vue&type=template&id=2946dd3b&scoped=true&"
import script from "./sampleViewModal.vue?vue&type=script&lang=js&"
export * from "./sampleViewModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2946dd3b",
  null
  
)

export default component.exports