<template>
  <div>
    <b-modal size="xl"
             title="Crear Recepción de Muestra"
             v-model="showModal"
             @openModal="openModal"
    >
      <div>
        <a-steps :current="current">
          <a-step v-for="item in steps" :key="item.title" :title="item.title" />
        </a-steps>
        <div class="steps-content">
          {{ steps[current].content }}
          <a-form-model layout="horizontal" :model="form" :rules="rules" ref="rule-Form">
            <template v-if="steps[current].key === 'general'">
              <div class="row text-justify">
                <div class="col-12 mt-2">
                  <a-card>
                    <a-row class="font-weight-bold">
                      Información General
                    </a-row>
                    <hr class="bg-primary">
                    <a-row class="mb-0">
                      <a-col :lg="{ span: 12, offset: 12 }" :md="{ span: 24 }">
                        <a-form-model-item label="No. de Solicitud Interna:"
                                           class="mx-1"
                                           ref="receipt_request"
                                           prop="receipt_request"
                        >
                          <a-input v-model="form.receipt_request" :disabled="blockFormFields"/>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row class="mb-0">
                      <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                        <a-form-model-item label="Planta que recibe la muestra:"
                                           class="mx-1"
                                           ref="work_center_id"
                                           prop="work_center_id"
                        >
                          <a-select v-model="form.work_center_id"
                                    placeholder="Seleccionar"
                                    show-search
                                    :filter-option="filterOption"
                                    :disabled="blockFormFields"
                          >
                            <a-select-option v-for="(option) in workCenters" :key="option.id" :value="option.id">
                              {{ option.code }} - {{ option.name }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                      <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                        <a-form-model-item label="Tipo de Recepción:"
                                           class="mx-1"
                                           ref="receipt_type"
                                           prop="receipt_type"
                        >
                          <a-select v-model="form.receipt_type"
                                    placeholder="Seleccionar"
                                    show-search
                                    :filter-option="filterOption"
                                    :disabled="blockFormFields"
                          >
                            <a-select-option v-for="(option) in receiptTypes" :key="option.name" :value="option.name">
                              {{ option.name }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row class="font-weight-bold">
                      Solicitante del Muestreo
                    </a-row>
                    <hr class="bg-primary">
                    <a-row class="mb-0">
                      <a-col :lg="{ span: 6 }" :md="{ span: 24 }">
                        <a-form-model-item label="Tipo de Solicitante:"
                                           class="mx-1"
                                           ref="client_type"
                                           prop="client_type"
                        >
                          <a-select v-model="form.client_type"
                                    placeholder="Seleccionar"
                                    show-search
                                    :filter-option="filterOption"
                                    :disabled="blockFormFields"
                          >
                            <a-select-option v-for="(option) in clientTypes" :key="option.name" :value="option.name">
                              {{ option.name }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                      <div v-show="form.client_type === 'Planta'">
                        <a-col :lg="{ span: 18 }" :md="{ span: 24 }">
                          <a-form-model-item label="Solicitante (Planta):"
                                             class="mx-1"
                                             ref="receipt_sample_clientable_id"
                                             prop="receipt_sample_clientable_id"
                          >
                            <a-select v-model="form.receipt_sample_clientable_id"
                                      placeholder="Seleccionar"
                                      show-search
                                      :filter-option="filterOption"
                                      :disabled="blockFormFields"
                            >
                              <a-select-option v-for="(option) in workCenters" :key="option.name" :value="option.name">
                                {{ option.code }} - {{ option.name }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </a-col>
                      </div>
                      <div v-show="form.client_type === 'Cliente'">
                        <a-col :lg="{ span: 18 }" :md="{ span: 24 }">
                          <a-form-model-item label="Solicitante (Cliente):"
                                             class="mx-1"
                                             ref="receipt_sample_clientable_id"
                                             prop="receipt_sample_clientable_id"
                          >
                            <a-select v-model="form.receipt_sample_clientable_id"
                                      show-search
                                      placeholder="Seleccionar"
                                      :filter-option="filterOption"
                                      :disabled="blockFormFields"
                            >
                              <a-select-option v-for="(option) in clients" :key="option.id" :value="option.id">
                                {{ option.code }} - {{ option.name }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </a-col>
                      </div>
                      <div v-show="form.client_type === 'Empleado'">
                        <a-col :lg="{ span: 18 }" :md="{ span: 24 }">
                          <a-form-model-item label="Solicitante (Empleado):"
                                             class="mx-1"
                                             ref="receipt_sample_clientable_id"
                                             prop="receipt_sample_clientable_id"
                          >
                            <a-select v-model="form.receipt_sample_clientable_id"
                                      show-search
                                      placeholder="Seleccionar"
                                      :filter-option="filterOption"
                                      :disabled="blockFormFields"
                            >
                              <a-select-option v-for="(option) in users" :key="option.id" :value="option.id">
                                {{ option.name }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </a-col>
                      </div>
                    </a-row>
                    <a-row class="font-weight-bold">
                      Fechas de la recepción
                    </a-row>
                    <hr class="bg-primary">
                    <a-row class="mb-0">
                      <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                        <a-form-model-item label="Fecha de Recepción:"
                                           class="mx-1"
                                           ref="receipt_dated_at"
                                           prop="receipt_dated_at"
                        >
                          <a-date-picker format="DD/MM/YYYY"
                                         v-model="form.receipt_dated_at"
                                         style="width: 100%"
                                         :disabled="blockFormFields"
                          />
                        </a-form-model-item>
                      </a-col>
                      <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                        <a-form-model-item label="Fecha Creación de Perfil:"
                                           class="mx-1"
                                           ref="profile_created_at"
                                           prop="profile_created_at"
                        >
                          <a-date-picker format="DD/MM/YYYY"
                                         v-model="form.profile_created_at"
                                         style="width: 100%"
                                         :disabled="blockFormFields"
                          />
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col>
                        <a-alert
                          message="Registro de Muestras"
                          :description="alertText"
                          :type="alertType"
                          show-icon
                        />
                      </a-col>
                    </a-row>
                  </a-card>
                </div>
              </div>
            </template>
            <template v-if="steps[current].key === 'samples'">
              <div class="row text-justify">
                <div class="col-12 mt-2">
                  <a-card>
                    <a-row class="font-weight-bold">
                      Información de la Muestra
                    </a-row>
                    <hr class="bg-primary">
                    <a-row class="mb-0">
                      <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                        <a-form-model-item label="Tipo de Muestra:"
                                           class="mx-1"
                                           ref="sample_type"
                                           prop="sample_type"
                        >
                          <a-select v-model="form.sample_type"
                                    show-search
                                    placeholder="Seleccionar"
                                    :filter-option="filterOption"
                                    :disabled="blockFormFields"
                          >
                            <a-select-option v-for="(option) in sampleTypes" :key="option.name" :value="option.name">
                              {{ option.name }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <div v-show="form.sample_type === SAMPLE_TYPE_MATERIAL">
                      <a-row class="mb-0">
                        <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                          <a-form-model-item label="Residuo:"
                                             class="mx-1"
                                             ref="material_id"
                                             prop="material_id"
                          >
                            <a-select v-model="sampleMaterial.material_id"
                                      show-search
                                      placeholder="Seleccionar"
                                      :filter-option="filterOption"
                                      :disabled="blockFormFields"
                                      label-in-value
                            >
                              <a-select-option v-for="(option) in materials" :key="option.id" :value="option.id">
                                {{ option.code }} - {{ option.name }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </a-col>
                        <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                          <a-form-model-item label="Tipo de Contenedor:"
                                             class="mx-1"
                                             ref="sample_container_type_id"
                                             prop="sample_container_type_id"
                          >
                            <a-select v-model="sampleMaterial.sample_container_type_id"
                                      show-search
                                      placeholder="Seleccionar"
                                      :filter-option="filterOption"
                                      :disabled="blockFormFields"
                                      label-in-value
                            >
                              <a-select-option v-for="(option) in sampleContainerTypes" :key="option.id" :value="option.id">
                                {{ option.name }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </a-col>
                      </a-row>
                      <a-row class="mb-0">
                        <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                          <a-form-model-item label="Cantidad de la muestra:"
                                             class="mx-1"
                                             ref="sample_quantity"
                                             prop="sample_quantity"
                          >
                            <a-input-number v-model="sampleMaterial.sample_quantity"
                                            :disabled="blockFormFields"
                                            style="width: 100%;"
                            />
                          </a-form-model-item>
                        </a-col>
                        <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                          <a-form-model-item label="Unidad de Medida:"
                                             class="mx-1"
                                             ref="u_m_s_id"
                                             prop="u_m_s_id"
                          >
                            <a-select v-model="sampleMaterial.u_m_s_id"
                                      show-search
                                      placeholder="Seleccionar"
                                      :filter-option="filterOption"
                                      :disabled="blockFormFields"
                                      label-in-value
                            >
                              <a-select-option v-for="(option) in ums" :key="option.id" :value="option.id">
                                {{ option.code }} - {{ option.name }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </a-col>
                      </a-row>
                    </div>
                    <div v-show="form.sample_type === SAMPLE_TYPE_MANUAL">
                      <a-row class="mb-0">
                        <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                          <a-form-model-item label="Muestra:"
                                             class="mx-1"
                                             ref="material_id"
                                             prop="material_id"
                          >
                            <a-input v-model="sampleManualMaterial.material_name"
                                     :disabled="blockFormFields"
                                     placeholder="Registrar muestra manual"
                            />
                          </a-form-model-item>
                        </a-col>
                        <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                          <a-form-model-item label="Tipo de Contenedor:"
                                             class="mx-1"
                                             ref="sample_container_type_id"
                                             prop="sample_container_type_id"
                          >
                            <a-select v-model="sampleManualMaterial.sample_container_type_id"
                                      show-search
                                      placeholder="Seleccionar"
                                      :filter-option="filterOption"
                                      :disabled="blockFormFields"
                                      label-in-value
                            >
                              <a-select-option v-for="(option) in sampleContainerTypes" :key="option.id" :value="option.id">
                                {{ option.name }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </a-col>
                      </a-row>
                      <a-row class="mb-0">
                        <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                          <a-form-model-item label="Cantidad de la muestra:"
                                             class="mx-1"
                                             ref="sample_quantity"
                                             prop="sample_quantity"
                          >
                            <a-input-number v-model="sampleManualMaterial.sample_quantity"
                                            :disabled="blockFormFields"
                                            style="width: 100%;"
                            />
                          </a-form-model-item>
                        </a-col>
                        <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                          <a-form-model-item label="Unidad de Medida:"
                                             class="mx-1"
                                             ref="u_m_s_id"
                                             prop="u_m_s_id"
                          >
                            <a-select v-model="sampleManualMaterial.u_m_s_id"
                                      show-search
                                      placeholder="Seleccionar"
                                      :disabled="blockFormFields"
                                      :filter-option="filterOption"
                                      label-in-value
                            >
                              <a-select-option v-for="(option) in ums" :key="option.id" :value="option.id">
                                {{ option.code }} - {{ option.name }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </a-col>
                      </a-row>
                    </div>
                    <div v-show="form.sample_type === SAMPLE_TYPE_BATCH">
                      <a-row class="mb-0">
                        <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                          <a-form-model-item label="Cantidad de la muestra:"
                                             class="mx-1"
                                             ref="sample_quantity"
                                             prop="sample_quantity"
                          >
                            <a-input-number v-model="sampleBatch.sample_quantity"
                                            :disabled="blockFormFields"
                                            style="width: 100%;"/>
                          </a-form-model-item>
                        </a-col>
                        <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                          <a-form-model-item label="Unidad de Medida:"
                                             class="mx-1"
                                             ref="u_m_s_id"
                                             prop="u_m_s_id"
                          >
                            <a-select v-model="sampleBatch.u_m_s_id"
                                      show-search
                                      placeholder="Seleccionar"
                                      :filter-option="filterOption"
                                      :disabled="blockFormFields"
                                      label-in-value
                            >
                              <a-select-option v-for="(option) in ums" :key="option.id" :value="option.id">
                                {{option.code}} - {{option.name}}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </a-col>
                      </a-row>
                      <a-row class="mb-0">
                        <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                          <a-form-model-item label="Folio de Lote:"
                                             class="mx-1"
                                             ref="invoice"
                                             prop="invoice"
                          >
                            <a-input v-model="sampleBatch.invoice"
                                     :disabled="blockFormFields"
                                     placeholder="Folio de Lote"
                            />
                          </a-form-model-item>
                        </a-col>
                      </a-row>
                      <a-row class="mb-0">
                        <a-col span="24">
                          <a-form-model-item label="Referencia del Lote:"
                                             class="mx-1"
                                             ref="reference"
                                             prop="reference"
                          >
                            <a-textarea rows="4"
                                        v-model="sampleBatch.reference"
                                        :disabled="blockFormFields"
                            />
                          </a-form-model-item>
                        </a-col>
                      </a-row>
                      <a-row>
                        <a-col cols="12" class="text-center">
                          <span class="text-danger font-weight-bold">AQUÍ FALTA LA INTEGRACIÓN DEL LOTE</span>
                        </a-col>
                      </a-row>
                    </div>
                    <div v-show="form.sample_type === SAMPLE_TYPE_WAREHOUSE">
                      <a-row>
                        <a-col class="text-center">
                              <span class="font-weight-bold text-danger">
                                INTEGRAR AQUÍ LA SELECCIÓN DE RESIDUOS DE ALMACÉN (SALIDAS)
                              </span>
                        </a-col>
                      </a-row>
                    </div>
                    <div v-if="modalType !== 'updateRecord'">
                      <hr class="bg-primary">
                      <div class="row mb-4" v-show="!load">
                        <div class="col-4 offset-4 text-center">
                          <responsive-button responsive="xl"
                                             variant="success"
                                             icon="archive-fill"
                                             text="Guardar"
                                             @ClickEvent="submit"
                                             block
                                             pill
                          />
                        </div>
                      </div>
                      <my-spinner :load="load" class="mb-4"/>
                    </div>
                  </a-card>
                </div>
              </div>
            </template>
          </a-form-model>
        </div>
        <div class="steps-action mt-2">
          <div class="row justify-content-center">
            <b-button variant="secondary" pill v-if="current > 0" @click="prev">
              <b-icon icon="arrow-left"/>
            </b-button>
            <b-button variant="primary" pill v-if="current < steps.length - 1" @click="next">
              <b-icon icon="arrow-right"/>
            </b-button>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="closeModal"/>
      </template>
    </b-modal>
  </div>
</template>

<script>

import moment from 'moment'
import api from '@/services/axios'
import { reagentMixin } from '@/mixins/core/reagentMixin'
import { SampleReceiptProvider } from '@/services/providers/SampleReceiptProvider'
import { Modal } from 'ant-design-vue'

export default {
  name: 'sampleViewModal',
  props: {
    showModal: {
      required: true,
      type: Boolean,
    },
    modalType: {
      type: String,
      required: true,
    },
    modalData: {
      type: Object,
      required: true,
    },
  },
  mixins: [reagentMixin],
  data() {
    return {
      // *************************************
      // CONSTANTS TO USE IN VIEW
      SAMPLE_TYPE_MANUAL: 'Manual',
      SAMPLE_TYPE_MATERIAL: 'Residuo',
      SAMPLE_TYPE_WAREHOUSE: 'Residuo de Almacén',
      SAMPLE_TYPE_BATCH: 'Lote',
      // CONSTANTS TO USE IN VIEW
      // *************************************
      current: 0,
      steps: [
        {
          title: 'Información General',
          key: 'general',
        },
        {
          title: 'Selección de Muestra',
          key: 'samples',
        },
      ],
      alertText: 'Para registrar la muestra de clic en el botón siguiente.',
      alertType: 'info',
      // ********** MODAL STRUCTURE **********
      modalResource: undefined,
      form: {
        work_center_id: '',
        receipt_type: '',
        receipt_request: '',
        sample_data: undefined,
        client_type: '',
        receipt_sample_clientable_id: '',
        sample_type: '',
        receipt_dated_at: moment(),
        profile_created_at: moment(),
      },
      // *************************************
      // SAMPLE DATA STRUCTURE
      sampleMaterial: {
        type_sample: 'materials',
        material_id: '',
        materials: {
          code: '',
          label: '',
        },
        sample_container_type_id: '',
        sampleContainerTypes: {
          code: '',
          label: '',
        },
        sample_quantity: 0,
        u_m_s_id: '',
        ums: {
          code: '',
          label: '',
        },
      },
      sampleManualMaterial: {
        type_sample: 'manual_materials',
        material_name: '',
        sample_container_type_id: '',
        sample_quantity: 0,
        u_m_s_id: '',
      },
      sampleBatch: {
        type_sample: 'Lote',
        batch_id: '0',
        invoice: '',
        sample_quantity: '',
        u_m_s_id: '',
        ums: {
          code: '',
          label: '',
        },
        reference: '',
      },
      sampleWarehouse: {
        type_sample: 'materials',
        titem_id: '',
        sample_container_type_id: '',
        sample_quantity: 0,
        u_m_s_id: '',
      },
      // SAMPLE DATA STRUCTURE
      // *************************************
      workCenters: [],
      clients: [],
      users: [],
      sampleContainerTypes: [],
      materials: [],
      ums: [],
      receiptTypes: [
        {
          name: 'Interna',
        },
        {
          name: 'Externa',
        },
        {
          name: 'Prueba de Compatibilidad',
        },
        {
          name: 'Prueba de Lote Terminado',
        },
      ],
      clientTypes: [
        {
          name: 'Empleado',
        },
        {
          name: 'Cliente',
        },
        {
          name: 'Planta',
        },
      ],
      sampleTypes: [
        {
          name: 'Manual',
        },
        {
          name: 'Residuo',
        },
        {
          name: 'Residuo de Almacén',
        },
        {
          name: 'Lote',
        },
      ],
      rules: {
        work_center_id: [
          {
            required: true,
            message: 'El campo de Centro de trabajo es obligatorio',
            trigger: 'blur',
          },
        ],
        receipt_type: [
          {
            required: true,
            message: 'El campo de Tipo de recepción es obligatorio',
            trigger: 'blur',
          },
        ],
        receipt_request: [
          {
            required: true,
            message: 'El campo de No. de Solicitud interna es obligatorio',
            trigger: 'blur',
          },
        ],
        client_type: [
          {
            required: true,
            message: 'El campo de Tipo de Solicitante es obligatorio',
            trigger: 'blur',
          },
        ],
        receipt_sample_clientable_id: [
          {
            required: true,
            message: 'El campo de Solicitante es obligatorio',
            trigger: 'blur',
          },
        ],
        sample_type: [
          {
            required: true,
            message: 'El campo de Tipo de Muestra es obligatorio',
            trigger: 'blur',
          },
        ],
        receipt_dated_at: [
          {
            required: true,
            message: 'El campo de Fecha de Recepción es obligatorio',
            trigger: 'blur',
          },
        ],
        profile_created_at: [
          {
            required: true,
            message: 'El campo de Fecha de Creación de Perfil es obligatorio',
            trigger: 'blur',
          },
        ],
      },
      load: false,
    }
  },
  computed: {
    blockFormFields: function() {
      return this.modalType === 'updateRecord'
    },
  },
  watch: {
    modalData: function() {
      this.setModalData(this.modalData)
    },
  },
  methods: {
    setModalData(values) {
      if (values && Object.keys(values).length === 0 && values.constructor === Object) {
        return 0
      }
      this.form.receipt_request = values.invoice
      this.form.work_center_id = values.workCenters.id
      this.form.receipt_type = values.sample_type
      this.form.client_type = values.customers.customerType
      if (values.customers.customerType === 'Empleado') {
        this.form.receipt_sample_clientable_id = `${values.customers.id}`
      } else {
        this.form.receipt_sample_clientable_id = values.customers.code
      }
      this.form.profile_created_at = moment(values.profile_created_at)
      this.form.receipt_dated_at = moment(values.receipt_dated_at)
      this.form.sample_type = values.materials.material_type
      if (values.materials.material_type === 'Manual') {
        this.sampleManualMaterial.type_sample = 'manual_materials'
        this.sampleManualMaterial.material_name = values.materials.material_name
        this.sampleManualMaterial.sample_container_type_id = {
          key: values.materials.sampleContainerTypes.code,
          label: ` ${values.materials.sampleContainerTypes.name} `,
        }
        this.sampleManualMaterial.sample_quantity = values.materials.quantity
        this.sampleManualMaterial.u_m_s_id = {
          key: values.materials.ums.code,
          label: ` ${values.materials.ums.name} `,
        }
      } else if (values.materials.material_type === 'Residuo') {
        this.sampleMaterial.type_sample = 'materials'
        this.sampleMaterial.material_id = values.materials.material_id
        this.sampleMaterial.materials.code = values.materials.material_id.key
        this.sampleMaterial.materials.label = values.materials.material_id.label
        this.sampleMaterial.sample_container_type_id = {
          key: values.materials.sampleContainerTypes.code,
          label: ` ${values.materials.sampleContainerTypes.name} `,
        }
        this.sampleMaterial.sampleContainerTypes.code = values.materials.sampleContainerTypes.code
        this.sampleMaterial.sampleContainerTypes.label = values.materials.sampleContainerTypes.name
        this.sampleMaterial.sample_quantity = values.materials.quantity
        this.sampleMaterial.u_m_s_id = {
          key: values.materials.ums.code,
          value: ` ${values.materials.ums.label} `,
        }
        this.sampleMaterial.ums.code = values.materials.ums.code
        this.sampleMaterial.ums.label = values.materials.ums.name
      } else if (values.materials.material_type === 'Residuo de Almacén') {

      } else if (values.materials.material_type === 'Lote') {
        this.sampleBatch.sample_quantity = values.materials.quantity
        this.sampleBatch.u_m_s_id = {
          key: values.materials.ums.code,
          label: ` ${values.materials.ums.name} `,
        }
        this.sampleBatch.invoice = values.materials.batchInvoice
        this.sampleBatch.reference = values.materials.reference
      }
    },
    // ******* SET MODAL DATA ******
    // ***************************
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // ***************************
    // ***** STEPS MODULE *****
    next() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          this.current++
          this.alertText = 'Para registrar la muestra de clic en el botón siguiente.'
          this.alertType = 'info'
          return 0
        }
        this.alertText = 'Usted no puede avanzar, debido a que el formulario tiene valores vacíos. Por favor revise su registro.'
        this.alertType = 'error'
      })
    },
    prev() {
      this.current--
    },
    // ***** STEPS MODULE *****
    // ***************************
    // ***************************
    // ***** MODAL FUNCTIONS *****
    closeModal(loadFetchTable = false) {
      this.form.work_center_id = ''
      this.form.receipt_type = ''
      this.form.receipt_request = ''
      this.form.client_type = ''
      this.form.receipt_sample_clientable_id = ''
      this.form.sample_type = ''
      this.form.receipt_dated_at = moment()
      this.form.profile_created_at = moment()
      // Reset alert component
      this.alertText = 'Para registrar la muestra de clic en el botón siguiente.'
      this.alertType = 'info'
      this.$emit('closeModal', { showModal: false, fetch: loadFetchTable })
    },
    openModal(data = {}) {
      this.modalResource = data
      this.showModal = true
    },
    submit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          this.load = true
          const sampleReceiptProvider = new SampleReceiptProvider()
          const strategyManager = sampleReceiptProvider.fillStrategies(
            this.sampleBatch,
            this.sampleManualMaterial,
            this.sampleMaterial,
            this.sampleWarehouse,
          )
          const strategyName = sampleReceiptProvider.selectStrategy(this.form.sample_type).strategyName
          const strategy = strategyManager.getStrategy(strategyName)
          this.form.sample_data = strategy.buildSampleObject()
          const request = {
            form: this.form,
          }
          const me = this
          api.post('/storeReceiptSample', request).then(response => {
            if (response.status === 200 || response.status) {
              me.load = false
              me.closeModal(true)
              Modal.success({
                title: '¡Muestra guardada!',
                content: 'Se ha almacenado correctamente la muestra con folio: ',
              })
            }
          }).catch(() => {
            me.load = false
          })
        } else {
          return false
        }
      })
    },
    // ***** MODAL FUNCTIONS *****
    // ***************************
    // ***************************
    async getMainCatalogs() {
      const params = {}
      this.workCenters = await this.GetResource('workCenters', params)
      this.clients = await this.GetResource('clients', params)
      this.users = await this.GetResource('users', params)
      this.materials = await this.GetResource('materials', params)
      this.ums = await this.GetResource('ums', params)
      this.sampleContainerTypes = await this.GetResource('sampleContainerTypes', params)
    },
  },
  mounted() {
    this.getMainCatalogs()
  },
}
</script>
<style scoped>

</style>
