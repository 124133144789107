<template>
  <div>
    <responsive-button variant="success"
                       icon="cart3"
                       responsive="xl"
                       :text="stateButtonText"
                       @ClickEvent="openCarModal"
                       block pill
    />
    <b-modal size="xl"
             title="Muestras añadidas para plan"
             v-model="showModal"
    >
      <b-container fluid>
        <a-list item-layout="horizontal" :data-source="stateTransactionReagents">
          <a-list-item slot="renderItem" slot-scope="item">
            <a-list-item-meta :description="`Familia: ` + item.reagent_family">
              <div class="row" slot="title">
                <div class="col-8">
                  <span class="font-weight-bold">Reactivo: </span>
                  {{ item.reagent_name }}
                </div>
                <div class="col-4">
                  <span class="font-weight-bold">Cantidad: </span>
                  {{ item.quantity }}
                </div>
              </div>
              <a-button slot="avatar" @click="deleteItemCart(item)">
                <a-icon type="delete" theme="twoTone" two-tone-color="#FF0000"/>
              </a-button>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="closeCarModal"/>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'receiptSampleCart',
  data() {
    return {
      showModal: false,
      buttonText: '0',
    }
  },
  computed: {
    ...mapState('reagents', ['state']),
    stateTransactionReagents () {
      return this.$store.state.reagents.transactionReagents
    },
    stateButtonText() {
      const xState = this.$store.state.reagents.transactionReagents
      const itemsNumber = xState.length
      return `${itemsNumber}`
    },
  },
  methods: {
    ...mapActions('reagents', ['STORE_REAGENT_DATA']),
    openCarModal() {
      this.showModal = true
    },
    closeCarModal() {
      this.showModal = false
    },
    deleteItemCart(data) {
      // Delete data to vuex
      this.$store.dispatch('reagents/DELETE_REAGENT_ITEM', { data })
    },
  },
}
</script>
