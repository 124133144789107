<template>
  <div>
    <searchingBar :components="['filter', 'sort', 'pages']"
                  :sortByOptions="sortByOptions"
                  @Fectch="fetchTable"
                  @filterValue="filterValue = $event"
                  @sortValue="tableObject.sortValue = $event"
                  @pageSize="tableObject.pagination.pageSize = $event"
                  @currentPageValue="tableObject.currentPage = $event"
    />
    <a-table :columns="tableObject.columns"
             :data-source="tableObject.data"
             :pagination="tableObject.pagination"
             :loading="tableObject.loading"
             @change="fetchTable"
             @openModal="openSample"
             @openWarehouseModal="openWarehouseModal"
             :row-key="record => record.code"
    >
      <template slot="workCenters" slot-scope="data">
        <span>{{ data.code }} -</span>
        <span>{{ data.name }} </span>
      </template>
      <template slot="invoices" slot-scope="data">
        <span class="text-center font-weight-bold">
          Recepción de muestra:
        </span>
        <br/>
        {{ data.code }}
        <hr class="bg-primary">
        <span class="text-center font-weight-bold">
          Código manual:
        </span>
        <br/>
        {{ data.manual_code }}
        <hr class="bg-primary">
        <span class="text-center font-weight-bold">
          Solicitud:
        </span>
        <br/>
        {{ data.invoice }}
      </template>
      <template slot="samples" slot-scope="data">
        <material-type-column :material-data="data"/>
      </template>
      <template slot="dates" slot-scope="data">
        <!--        {{ data }}-->
        <span class="font-weight-bold">
          Recepción:
        </span>
        <br/>
        {{
          moment(data.receipt_dated_at).format('YYYY-MM-DD HH:mm')
        }}
        <hr class="bg-primary">
        <span class="font-weight-bold">
          Creación del perfil:
        </span>
        <br/>
        {{
          moment(data.profile_created_at).format('YYYY-MM-DD HH:mm')
        }}
      </template>
      <template slot="customers" slot-scope="data">
        <customer-type-column :customer-data="data"/>
      </template>
      <template slot="status" slot-scope="data">
        <span class="font-weight-bold text-center">
          Estatus de muestra:
        </span>
        <br>
        <span v-if="data.sample_status === SAMPLE_PLAN_CHECK"
              class="text-success font-weight-bold"
        >
          <a-icon type="check-circle" />
          {{ data.sample_status }}
        </span>
        <span class="text-danger font-weight-bold" v-else>
          <a-icon type="clock-circle" />
          {{ data.sample_status }}
        </span>
        <hr class="bg-primary">
        <span class="font-weight-bold text-center">
          Estatus de almacén:
        </span>
        <br>
        <span v-if="data.warehouse_status === WAREHOUSE_STATUS_ENABLE || data.warehouse_status === WAREHOUSE_STATUS_FORMULATED"
              class="text-success font-weight-bold"
        >
          <a-icon type="check-circle" />
          {{ data.warehouse_status }}
        </span>
        <span v-else-if="data.warehouse_status === WAREHOUSE_STATUS_ANALYSIS"
              class="text-warning font-weight-bold"
        >
          <a-icon type="experiment" />
          {{ data.warehouse_status }}
        </span>
        <span v-else
              class="text-danger font-weight-bold"
        >
          <a-icon type="delete" />
          {{ data.warehouse_status }}
        </span>
      </template>
      <template slot="action" slot-scope="data">
        <div v-if="sampleProcess === 'receiptSampling'">
          <b-button variant="success" @click="openWarehouseModal(data)" pill>
            <div class="d-none d--block" v-b-tooltip.hover title="Ingresar al almacén">
              <b-icon icon="cart-plus"/> Ingresar al almacén
            </div>
            <div class="d--none" v-b-tooltip.hover title="Ingresar al almacén">
              <b-icon icon="house-fill"/>
            </div>
          </b-button>
          <b-button variant="secondary" @click="openSample('edit', data)" pill>
            <div class="d-none d--block" v-b-tooltip.hover title="Editar">
              <b-icon icon="pencil-square"/> Editar
            </div>
            <div class="d--none" v-b-tooltip.hover title="Editar">
              <b-icon icon="pencil-square"/>
            </div>
          </b-button>
          <a-popconfirm placement="topLeft" ok-text="SI" cancel-text="NO" @confirm="deleteSample(data)">
            <template slot="title">
              <p class="font-weight-bold text-danger">
                ¿Desea eliminar la muestra {{ data.code }}?
              </p>
              <p>Se eliminará la muestra seleccionada, este será un proceso irreversible,
                tenga cuidado en su elección.
              </p>
            </template>
            <a-button type="danger" shape="round" icon="delete" />
          </a-popconfirm>
        </div>
        <div v-else>
          NA
<!--          <b-button variant="success" @click="openWarehouseModal(data)" pill>-->
<!--            <div class="d-none d&#45;&#45;block" v-b-tooltip.hover title="Asignar Almacén">-->
<!--              <b-icon icon="box-arrow-down"/>-->
<!--              Asignar Almacén-->
<!--            </div>-->
<!--            <div class="d&#45;&#45;none" v-b-tooltip.hover title="Asignar Almacén">-->
<!--              <b-icon icon="box-arrow-down"/>-->
<!--            </div>-->
<!--          </b-button>-->
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import CustomerTypeColumn from '@/views/geocycle/laboratories/sample_receipt/components/customerTypeColumn'
import MaterialTypeColumn from '@/views/geocycle/laboratories/sample_receipt/components/materialTypeColumn'
import { Modal } from 'ant-design-vue'

export default {
  name: 'sampleViewTable',
  components: { MaterialTypeColumn, CustomerTypeColumn },
  props: {
    tableObject: {
      type: Object,
      required: true,
    },
    sampleProcess: {
      default: 'receiptSampling',
      type: String,
    },
  },
  mixins: [fractalMixin],
  data() {
    return {
      // ******************************************
      // CONSTANTS
      SAMPLE_PLAN_CHECK: 'Plan de Muestreo Asignado',
      WAREHOUSE_STATUS_ENABLE: 'Disponible en Almacén',
      WAREHOUSE_STATUS_DEFINITY_OUTPUT: 'Salida Parcial',
      WAREHOUSE_STATUS_PARTIAL_OUTPUT: 'Baja Definitiva',
      WAREHOUSE_STATUS_ANALYSIS: 'En Análisis',
      WAREHOUSE_STATUS_FORMULATED: 'Formulado',
      WAREHOUSE_STATUS_NOT_ASSIGNED: 'No Asignada',
      // CONSTANTS
      // ******************************************
      sortByOptions: [],
      filterValue: '',
      selectedRowKeys: [],
      selectRowObjects: [],
      resourceData: undefined,
      resourceType: 'receiptSamples',
    }
  },
  methods: {
    async fetchTable(pagEvent = {}) {
      const params = {
        'page[number]': (Object.keys(pagEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.tableObject.currentPage}`,
        'page[size]': `${this.tableObject.pagination.pageSize}`,
        include: 'workCenters,ums',
      }
      if (this.filterValue !== '') params['filter[global]'] = this.filterValue
      await this.GetTableResource(pagEvent, this.resourceType, this.tableObject, params)
      this.resourceData = this.tableObject
      this.$emit('fetchTable', this.resourceData)
    },
    openSample(action, data = {}) {
      this.$emit('openModal', action, data)
    },
    deleteSample(data = {}) {
      const me = this
      const deleteObject = {
        jsonApi: {
          type: me.resourceType,
          id: data.id,
        },
      }
      me.$store.dispatch('jv/delete', [deleteObject, {}])
        .then((data) => {
          Modal.success({
            title: 'Eliminado',
            content: 'Se ha eliminado correctamente la muestra.',
            okText: 'Ok',
            centered: true,
          })
          me.fetchTable()
        })
        .catch((errors) => {
          console.log(errors)
        })
    },
    openWarehouseModal(data = {}) {
      this.$emit('openWarehouseModal', data)
    },
  },
  mounted() {
    this.fetchTable()
  },
}
</script>
