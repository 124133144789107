import { TYPE_WAREHOUSE } from '@/services/providers/sampleStrategy/StrategyManager'

export class SampleWarehouseStrategy {
  constructor(data) {
    this._tItem = data.titem_id
    this._sampleContainerTypeId = data.sample_container_type_id.key
    this._sampleContainerTypes = {
      code: data.sample_container_type_id.key,
      name: data.sample_container_type_id.label,
    }
    this._quantity = data.sample_quantity
    this._umsId = data.u_m_s_id.key
    this._ums = {
      code: data.u_m_s_id.key,
      name: data.u_m_s_id.label,
    }
  }

  buildSampleObject() {
    return {
      material_type: TYPE_WAREHOUSE,
      t_item: this._tItem,
      sample_container_type_id: this._sampleContainerTypeId,
      sampleContainerTypes: this._sampleContainerTypes,
      quantity: this._quantity,
      u_m_s_id: this._umsId,
      ums: this._ums,
      currents: this._currents,
    }
  }
}
