export const TYPE_REMAINDER = 'Residuo'
export const TYPE_MANUAL = 'Manual'
export const TYPE_WAREHOUSE = 'Residuo de Almacén'
export const TYPE_BATCH = 'Lote'

export class StrategyManager {
  constructor() {
    this._strategies = []
  }

  addStrategy(strategy) {
    this._strategies = [...this._strategies, strategy]
  }

  getStrategy(name) {
    return this._strategies.find(strategy => strategy._name === name)
  }
}
