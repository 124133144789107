export class Strategy {
  constructor(name, handler) {
    this._name = name
    this._handler = handler
  }

  buildSampleObject() {
    return this._handler()
  }
}
