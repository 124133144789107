<template>
  <div>
    <div class="air__utils__heading"><h5>Asignar a Plan de Muestreo</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-2 offset-10">
            <receipt-sample-cart/>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-12">
            <a-steps :current="current">
              <a-step v-for="item in steps" :key="item.title" :title="item.title" />
            </a-steps>
            <div class="steps-content">
              {{ steps[current].content }}
              <template v-if="steps[current].key === 'general'">
                <a-form-model layout="horizontal" :model="form" :rules="rules" ref="rule-Form">
                  <div class="row text-justify m-2">
                    <div class="col-12">
                      <a-row class="font-weight-bold">
                        Información General
                      </a-row>
                      <hr class="bg-primary">
                      <a-row class="mb-0">
                        <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                          <a-form-model-item label="Muestreo:"
                                             class="mx-1"
                                             ref="plan_sample_type"
                                             prop="plan_sample_type"
                          >
                            <a-select v-model="form.plan_sample_type"
                                      show-search
                                      placeholder="Seleccionar"
                                      :filter-option="filterOption"
                            >
                              <a-select-option v-for="(option) in planSampleTypes" :key="option.name" :value="option.name">
                                {{ option.name }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </a-col>
                        <a-col :lg="{ span: 12 }" :md="{ span: 24 }">
                          <a-form-model-item label="Preparación de Muestra:"
                                             class="mx-1"
                                             ref="sample_prepared"
                                             prop="sample_prepared"
                          >
                            <a-select v-model="form.sample_prepared"
                                      show-search
                                      placeholder="Seleccionar"
                                      :filter-option="filterOption"
                            >
                              <a-select-option v-for="(option) in planPreparedSamples" :key="option.name" :value="option.name">
                                {{ option.name }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </a-col>
                      </a-row>
                      <a-row class="font-weight-bold pt-2">
                        <a-col :lg="{ span: 8 }" :md="{ span: 24 }">
                          Medida de Seguridad
                        </a-col>
                        <a-col :lg="{ span: 16 }" :md="{ span: 24 }">
                          <a-checkbox @change="onChange">
                            Habilitar el Registro de Información
                          </a-checkbox>
                        </a-col>
                      </a-row>
                      <hr class="bg-primary">
                      <a-row v-if="form.security_flag">
                        <a-col :lg="{ span: 4 }" :md="{ span: 24 }">
                          <a-form-model-item label="CO:"
                                             class="mx-1"
                                             ref="co"
                                             prop="co"
                          >
                            <a-input v-model="form.security.co" placeholder="CO"/>
                          </a-form-model-item>
                        </a-col>
                        <a-col :lg="{ span: 4 }" :md="{ span: 24 }">
                          <a-form-model-item label="CO2:"
                                             class="mx-1"
                                             ref="co2"
                                             prop="co2"
                          >
                            <a-input v-model="form.security.co2" placeholder="CO2"/>
                          </a-form-model-item>
                        </a-col>
                        <a-col :lg="{ span: 4 }" :md="{ span: 24 }">
                          <a-form-model-item label="H2S:"
                                             class="mx-1"
                                             ref="h2s"
                                             prop="h2s"
                          >
                            <a-input v-model="form.security.h2s" placeholder="H2S"/>
                          </a-form-model-item>
                        </a-col>
                        <a-col :lg="{ span: 4 }" :md="{ span: 24 }">
                          <a-form-model-item label="PH:"
                                             class="mx-1"
                                             ref="ph"
                                             prop="ph"
                          >
                            <a-input v-model="form.security.ph" placeholder="PH"/>
                          </a-form-model-item>
                        </a-col>
                        <a-col :lg="{ span: 8 }" :md="{ span: 24 }">
                          <a-form-model-item label="Temperatura de Recipientes:"
                                             class="mx-1"
                                             ref="temperature"
                                             prop="temperature"
                          >
                            <a-input v-model="form.security.temperature" placeholder="Temperatura de Recipientes"/>
                          </a-form-model-item>
                        </a-col>
                      </a-row>
                      <a-row class="font-weight-bold pt-4">
                        <a-col :lg="{ span: 8 }" :md="{ span: 24 }">
                          EPP obligatorio
                        </a-col>
                        <a-col :lg="{ span: 16 }" :md="{ span: 24 }">
                          <a-checkbox @change="onChangePPE">
                            Habilitar el Registro de Información
                          </a-checkbox>
                        </a-col>
                      </a-row>
                      <hr class="bg-primary">
                      <a-row v-if="form.ppe_flag">
                        <a-col :lg="{ span: 5 }" :md="{ span: 24 }">
                          <a-checkbox @change="onChangeShoes">
                            Zapatos de Seguridad
                          </a-checkbox>
                        </a-col>
                        <a-col :lg="{ span: 5 }" :md="{ span: 24 }">
                          <a-checkbox @change="onChangeTyek">
                            Tyek
                          </a-checkbox>
                        </a-col>
                        <a-col :lg="{ span: 5 }" :md="{ span: 24 }">
                          <a-checkbox @change="onChangeFullFace">
                            Full Face
                          </a-checkbox>
                        </a-col>
                        <a-col :lg="{ span: 5 }" :md="{ span: 24 }">
                          <a-checkbox @change="onChangeHelmet">
                            Casco de Seguridad
                          </a-checkbox>
                        </a-col>
                        <a-col :lg="{ span: 4 }" :md="{ span: 24 }">
                          <a-checkbox @change="onChangeGloves">
                            Guantes
                          </a-checkbox>
                        </a-col>
                      </a-row>
                      <a-row class="font-weight-bold pt-4">
                        <a-col :lg="{ span: 24 }" :md="{ span: 24 }">
                          Muestreo
                        </a-col>
                      </a-row>
                      <hr class="bg-primary">
                      <a-row>
                        <a-col :lg="{ span: 8 }" :md="{ span: 24 }">
                          <a-checkbox @change="onChangeDifficultSampling">
                            ¿Presenta dificultad al tomar la muestra?
                          </a-checkbox>
                        </a-col>
                        <a-col :lg="{ span: 16 }" :md="{ span: 24 }">
                          <a-form-model-item label="Dificultad al tomar la muestra:"
                                             class="mx-1"
                                             ref="difficult_sampling_reason"
                                             prop="difficult_sampling_reason"
                          >
                            <a-textarea placeholder="Explique dificultad"
                                        v-model="form.difficult_sampling_reason"
                            />
                          </a-form-model-item>
                        </a-col>
                      </a-row>
                      <a-row>
                        <a-col :lg="{ span: 8 }" :md="{ span: 24 }">
                          <a-checkbox @change="onChangeRepresentativeSampling">
                            ¿El muestreo es representativo?
                          </a-checkbox>
                        </a-col>
                        <a-col :lg="{ span: 16 }" :md="{ span: 24 }">
                          <a-form-model-item label="Motivo del muestreo representativo:"
                                             class="mx-1"
                                             ref="representative_sampling_reason"
                                             prop="representative_sampling_reason"
                          >
                            <a-textarea placeholder="Explique el motivo"
                                        v-model="form.representative_sampling_reason"
                            />
                          </a-form-model-item>
                        </a-col>
                      </a-row>
                      <a-row class="font-weight-bold pt-2">
                        <a-col :lg="{ span:24 }" :md="{ span: 24 }">
                          Responsables
                        </a-col>
                      </a-row>
                      <hr class="bg-primary">
                      <a-row>
                        <a-col :lg="{ span: 24 }" :md="{ span: 24 }">
                          <a-form-model-item label="Quién entregó la muestra:"
                                             class="mx-1"
                                             ref="deliver_user"
                                             prop="deliver_user"
                          >
                            <a-input v-model="form.deliver_user" placeholder="Ingrese el nombre"/>
                          </a-form-model-item>
                        </a-col>
                        <a-col :lg="{ span: 24 }" :md="{ span: 24 }">
                          <a-form-model-item label="Quién recibe la muestra:"
                                             class="mx-1"
                                             ref="receipt_user"
                                             prop="receipt_user"
                          >
                            <a-input v-model="form.receipt_user" placeholder="Ingrese el nombre"/>
                          </a-form-model-item>
                        </a-col>
                      </a-row>
                      <hr class="bg-primary">
                      <div class="row mb-4" v-show="!form.load">
                        <div class="col-4 offset-4 text-center">
                          <responsive-button responsive="xl"
                                             variant="success"
                                             icon="archive-fill"
                                             text="Guardar"
                                             @ClickEvent="submit"
                                             block
                                             pill
                          />
                        </div>
                      </div>
                      <my-spinner :load="form.load" class="mb-4"/>
                    </div>
                  </div>
                </a-form-model>
              </template>
              <template v-if="steps[current].key === 'samples'">
                <sample-view-modal :show-modal="showModal"
                                   @closeModal="closeSampleModal"
                                   :modal-type="'updateRecord'"
                />
                <sample-view-table :table-object="tableObject"
                                   sample-process="samplingPlan"
                                   @change="fetchTable"
                                   @openModal="openSampleModal"
                />
              </template>
            </div>
            <div class="steps-action">
              <div class="row justify-content-center">
                <b-button variant="secondary" pill v-if="current > 0" @click="prev">
                  <b-icon icon="arrow-left"/>
                </b-button>
                <b-button variant="primary" pill v-if="current < steps.length - 1" @click="next">
                  <b-icon icon="arrow-right"/>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReceiptSampleCart from '@/views/geocycle/laboratories/sample_receipt/components/receiptSampleCart'
import SampleViewTable from '@/views/geocycle/laboratories/sample_receipt/sampleViewTable'
import { SampleReceiptProvider } from '@/services/providers/SampleReceiptProvider'
import SampleViewModal from '@/views/geocycle/laboratories/sample_receipt/components/sampleViewModal'

export default {
  name: 'samplePlanForm',
  components: { SampleViewModal, SampleViewTable, ReceiptSampleCart },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'laboratories',
          breadcrumbName: 'Laboratorio',
        },
        {
          name: 'sampleReceiptMain',
          breadcrumbName: 'Recepción de Muestras',
        },
        {
          breadcrumbName: 'Asignar a Plan de Muestreo',
          aIcon: '',
        },
      ],
      // *******************************************
      // *******************************************
      // MODAL VARIABLES
      showModal: false,
      // MODAL VARIABLES
      // *******************************************
      // *******************************************
      // *******************************************
      // *******************************************
      // FORM VARIABLES
      rules: {
        plan_sample_type: [
          {
            required: true,
            message: 'El campo de Tipo de Muestreo es obligatorio',
            trigger: 'blur',
          },
        ],
        sample_prepared: [
          {
            required: true,
            message: 'El campo de Preparación de Muestra es obligatorio',
            trigger: 'blur',
          },
        ],
        deliver_user: [
          {
            required: true,
            message: 'El campo de Quién entregó la muestra es obligatorio',
            trigger: 'blur',
          },
        ],
        receipt_user: [
          {
            required: true,
            message: 'El campo de Quién recibe la muestra es obligatorio',
            trigger: 'blur',
          },
        ],
      },
      form: {
        plan_sample_type: '',
        sample_prepared: '',
        security_flag: false,
        load: false,
        security: {
          co: undefined,
          co2: undefined,
          h2s: undefined,
          temperature: undefined,
          ph: undefined,
        },
        ppe: {
          shoes: false,
          gloves: false,
          tyek: false,
          full_face: false,
          helmet: false,
        },
        ppe_flag: false,
        representative_sampling_check: false,
        representative_sampling_reason: '',
        difficult_sampling_check: false,
        difficult_sampling_reason: '',
        deliver_user: '',
        receipt_user: '',
      },
      planSampleTypes: [
        {
          name: 'Individual',
        },
        {
          name: 'Material Compósito',
        },
      ],
      planPreparedSamples: [
        {
          name: 'Triturado',
        },
        {
          name: 'Mezclado',
        },
        {
          name: 'Ninguno',
        },
      ],
      current: 0,
      steps: [
        {
          title: 'Datos del Plan de Muestreo',
          key: 'general',
        },
        {
          title: 'Ingreso de Muestras',
          key: 'samples',
        },
      ],
      // FORM VARIABLES
      // *******************************************
      // *******************************************
      tableObject: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Centro de Trabajo',
            key: '',
            dataIndex: 'workCenters',
            class: 'small text-center',
            scopedSlots: { customRender: 'workCenters' },
          },
          {
            title: 'Claves de la Solicitud',
            key: '',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'invoices' },
          },
          {
            title: 'Tipo',
            key: '',
            dataIndex: 'sample_type',
            class: 'small text-center',
          },
          {
            title: 'Muestra',
            key: '',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'samples' },
          },
          {
            title: 'Fechas',
            key: '',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'dates' },
          },
          {
            title: 'Solicitante',
            key: '',
            dataIndex: 'customers',
            class: 'small text-center',
            scopedSlots: { customRender: 'customers' },
          },
          {
            title: 'Estatus',
            key: '',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'status' },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
            // fixed: 'right',
            // width: '20%',
          },
        ],
      },
    }
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    async fetchTable() {
      const sampleReceiptProvider = new SampleReceiptProvider()
      this.tableObject.data = await sampleReceiptProvider.get()
    },
    next() {
      this.current++
      // this.$refs['rule-Form'].validate(valid => {
      //   if (valid) {
      //     this.current++
      //     this.alertText = 'Para registrar la muestra de clic en el botón siguiente.'
      //     this.alertType = 'info'
      //     return 0
      //   }
      //   this.alertText = 'Usted no puede avanzar, debido a que el formulario tiene valores vacíos. Por favor revise su registro.'
      //   this.alertType = 'error'
      // })
    },
    prev() {
      this.current--
    },
    openSampleModal(data = {}) {
      this.showModal = true
    },
    closeSampleModal(value) {
      this.showModal = value
    },
    // *********************************************
    // FORM
    onChange(e) {
      this.form.security_flag = e.target.checked
    },
    onChangePPE(e) {
      this.form.ppe_flag = e.target.checked
    },
    onChangeRepresentativeSampling(e) {
      this.form.representative_sampling_check = e.target.checked
    },
    onChangeDifficultSampling(e) {
      this.form.difficult_sampling_check = e.target.checked
    },
    onChangeShoes(e) {
      this.form.ppe.shoes = e.target.checked
    },
    onChangeGloves(e) {
      this.form.ppe.gloves = e.target.checked
    },
    onChangeTyek(e) {
      this.form.ppe.tyek = e.target.checked
    },
    onChangeFullFace(e) {
      this.form.ppe.full_face = e.target.checked
    },
    onChangeHelmet(e) {
      this.form.ppe.helmet = e.target.checked
    },
    submit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          this.form.load = true
        }
      })
    },
    // FORM
    // *********************************************
  },
  mounted() {
    this.fetchTable()
  },
}
</script>
<style scoped>
  .steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
  }

  .steps-action {
    margin-top: 24px;
  }
</style>
