<template>
  <div v-if="customerData && Object.keys(customerData).length > 0">
    <span class="font-weight-bold">
        Tipo:
      </span>
    <br/>
    {{
      customerData.customerType
    }}
    <hr class="bg-primary">
    <div v-if="customerData.customerType === CUSTOMER.client">
      <span class="font-weight-bold">
        Código:
      </span>
      <br/>
      {{ customerData.code }} - {{ customerData.name }}
    </div>
    <div v-else-if="customerData.customerType === CUSTOMER.employee">
      <span class="font-weight-bold">
        Nombre:
      </span>
      <br/>
      {{ customerData.name }}
    </div>
    <div v-else-if="customerData.customerType === CUSTOMER.workCenter">
      <span class="font-weight-bold">
        Nombre:
      </span>
      <br/>
      {{ customerData.code }} - {{ customerData.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'customerTypeColumn',
  props: {
    customerData: {
      required: true,
    },
  },
  data() {
    return {
      CUSTOMER: {
        client: 'Cliente',
        employee: 'Empleado',
        workCenter: 'Planta',
      },
    }
  },
}
</script>

<style scoped>

</style>
