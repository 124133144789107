<template>
  <div v-if="materialData && Object.keys(materialData).length > 0 && materialData.materials">
    <a-row v-if="materialData.materials">
      <a-col :span="10" class="text-right">
        <span class="font-weight-bold text-right">
          Tipo:
        </span>
      </a-col>
      <a-col :span="14" class="text-left pl-1">
        {{ materialData.materials.material_type }}
      </a-col>
    </a-row>
    <hr class="bg-primary">
    <a-row>
      <a-col :span="10" class="text-right">
        <span class="font-weight-bold text-right">
          Cantidad:
        </span>
      </a-col>
      <a-col :span="14" class="text-left pl-1">
        {{ materialData.quantity }}
      </a-col>
    </a-row>
    <a-row v-if="materialData.ums">
      <a-col :span="10" class="text-right">
        <span class="font-weight-bold text-right">
          UM:
        </span>
      </a-col>
      <a-col :span="14" class="text-left pl-1">
        {{ materialData.ums.code }} - {{ materialData.ums.name }}
      </a-col>
    </a-row>
    <hr class="bg-primary">
    <div v-if="materialData.materials && materialData.materials.material_type === MATERIAL_TYPE.manual">
      <a-row v-if="materialData.materials.material_name">
        <a-col :span="10" class="text-right">
          <span class="font-weight-bold text-right">
            Residuo:
          </span>
        </a-col>
        <a-col :span="14" class="text-left pl-1">
          {{ materialData.materials.material_name }}
        </a-col>
      </a-row>
      <a-row v-if="materialData.materials.sampleContainerTypes">
        <a-col :span="10" class="text-right">
          <span class="font-weight-bold text-right">
            Contenedor:
          </span>
        </a-col>
        <a-col :span="14" class="text-left pl-1">
          {{ materialData.materials.sampleContainerTypes.name }}
        </a-col>
      </a-row>
    </div>
    <div v-else-if="materialData.materials && materialData.materials.material_type === MATERIAL_TYPE.material">
      <a-row v-if="materialData.materials.material_id">
        <a-col :span="10" class="text-right">
          <span class="font-weight-bold text-right">
            Residuo:
          </span>
        </a-col>
        <a-col :span="14" class="text-left pl-1">
          {{ materialData.materials.material_id.label }}
        </a-col>
      </a-row>
      <a-row v-if="materialData.materials.sampleContainerTypes">
        <a-col :span="10" class="text-right">
          <span class="font-weight-bold text-right">
            Contenedor:
          </span>
        </a-col>
        <a-col :span="14" class="text-left pl-1">
          {{ materialData.materials.sampleContainerTypes.name }}
        </a-col>
      </a-row>
    </div>
    <div v-else-if="materialData.materials.material_type === MATERIAL_TYPE.warehouse">
      {{materialData.materials}}
    </div>
    <div v-else-if="materialData.materials && materialData.materials.material_type === MATERIAL_TYPE.batch">
      <a-row v-if="materialData.materials.batchInvoice">
        <a-col :span="10" class="text-right">
          <span class="font-weight-bold text-right">
            Folio:
          </span>
        </a-col>
        <a-col :span="14" class="text-left pl-1">
          {{ materialData.materials.batchInvoice }}
        </a-col>
      </a-row>
      <a-row v-if="materialData.materials.reference">
        <a-col :span="10" class="text-right">
          <span class="font-weight-bold text-right">
            Referencia:
          </span>
        </a-col>
        <a-col :span="14" class="text-left pl-1">
          {{ materialData.materials.reference }}
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'materialTypeColumn',
  props: {
    materialData: {
      required: true,
    },
  },
  data() {
    return {
      MATERIAL_TYPE: {
        manual: 'Manual',
        material: 'Residuo',
        warehouse: 'Residuo de Almacén',
        batch: 'Lote',
      },
    }
  },
}
</script>

<style scoped>

</style>
